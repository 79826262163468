import { ErrorComponent, HttpError, useCan } from "@pankod/refine-core";
import { Result } from "antd";
import { useLocation } from "react-router-dom";

export const CustomErrorComponent = ({ error }: { error?: HttpError }) => {
  const location = useLocation();

  const pathSegments = location.pathname.split("/");

  let resource = pathSegments[1];
  if (resource === "prize" || resource === "label") {
    resource = "wof/" + resource;
  }
  const { data: canAccessContents } = useCan({
    resource: resource,
    action: "list",
  });
  if (canAccessContents && canAccessContents.can === false) {
    return (
      <Result status="403" title="403" subTitle={canAccessContents.reason} />
    );
  }

  return <ErrorComponent></ErrorComponent>;
};
