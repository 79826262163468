import {
  DeleteButton,
  EditButton,
  Icons,
  List,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { Table } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Catalog } from "interfaces/catalog";
import { CatalogCreate } from "./create"; // Assuming this is the modal component you created earlier
import dayjs from "dayjs";
import { CatalogEdit } from "./edit";

export const CatalogList: React.FC<IResourceComponentsProps> = ({ name }) => {
  const { tableProps } = useTable<Catalog.CatalogItem>({
    resource: name,
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  const {
    formProps: catalogCreateFormProps,
    modalProps: catalogCreateModalProps,
    show: catalogCreateModalShow,
  } = useModalForm({
    resource: name,
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Katalog başarıyla oluşturuldu.",
    },
    onMutationSuccess: () => {
      catalogCreateFormProps.form.resetFields();
    },
  });

  const {
    formProps: catalogEditFormProps,
    modalProps: catalogEditModalProps,
    show: catalogEditModalShow,
  } = useModalForm<Catalog.CatalogItem>({
    resource: name,
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Katalog başarıyla güncellendi.",
    },
  });

  return (
    <List
      resource={name}
      title="Kataloglar"
      createButtonProps={{
        type: "primary",
        size: "large",
        shape: "round",
        icon: <Icons.PlusCircleFilled />,
        onClick: () => catalogCreateModalShow(),
        children: "Yeni Katalog Oluştur",
      }}
    >
      <Table {...tableProps}>
        <Table.Column
          dataIndex="name"
          title="İsim"
          key="name"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Oluşturulma Tarihi"
          key="createdAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Güncellenme Tarihi"
          key="updatedAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column<Catalog.CatalogItem>
          title="İşlemler"
          dataIndex="actions"
          render={(_, record) => (
            <>
              <EditButton
                resourceNameOrRouteName={name}
                size="small"
                onClick={() => catalogEditModalShow(record.id)}
              >
                Düzenle
              </EditButton>
              <DeleteButton
                resourceNameOrRouteName={name}
                recordItemId={record.id}
                size="small"
                successNotification={{
                  type: "success",
                  message: "Katalog başarıyla silindi.",
                }}
              >
                Sil
              </DeleteButton>
            </>
          )}
        />
      </Table>

      {/* Modal for creating a catalog */}
      <CatalogCreate
        formProps={catalogCreateFormProps}
        modalProps={catalogCreateModalProps}
      />
      <CatalogEdit
        formProps={catalogEditFormProps}
        modalProps={catalogEditModalProps}
      />
    </List>
  );
};
