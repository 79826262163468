import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  ModalProps,
  FormProps,
  Select,
  Upload,
  message,
  Switch,
} from "@pankod/refine-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Label } from "interfaces";
import { Campaigns } from "interfaces/campaigns";
import { file2Base64, useCustom } from "@pankod/refine-core";
import ENV from "utils/env";

type Props = {
  formProps: FormProps & { initialValues?: any };
  modalProps: ModalProps;
};

interface WofLabelFormValues {
  title: string;
  text: string;
  campaignId: string;
  isActive: boolean;
  imageUrl: string;
  order: number;
}

export const EditWofLabel: React.FC<Props> = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null);

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign`,
    method: "get",
    config: {
      query: {
        campaignType: "wof",
      },
    },
    errorNotification: false,
    queryOptions: {
      retry: 0,
    },
  });

  useEffect(() => {
    if (formProps.initialValues) {
      resetForm();
    }
  }, [formProps.initialValues]);

  const resetForm = () => {
    const initialValues = formProps.initialValues || {};
    formProps.form?.setFieldsValue(initialValues);

    if (initialValues.imageUrl) {
      const existingFile = [
        {
          uid: "1",
          name: "Image",
          status: "done",
          url: initialValues.imageUrl,
        },
      ];
      setFileList(existingFile);
      setBase64Image(initialValues.imageUrl);
    } else {
      setFileList([]);
      setBase64Image(null);
    }
  };

  const onFinish = async (values: WofLabelFormValues) => {
    const formattedValues = {
      ...values,
      imageUrl: base64Image || "",
    };
    return formProps.onFinish?.(formattedValues);
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  if (isError) {
    return null;
  }

  const campaignSelectProps = {
    options: response?.data?.map((campaign: Campaigns.CampaignItem) => ({
      label: campaign.label,
      value: campaign.id,
    })),
    loading: isFetching,
  };

  return (
    <Modal
      {...modalProps}
      title="Çark Dilimi Düzenle"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        resetForm();
      }}
    >
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item<Label.LabelItem>
          label="Başlık"
          name="title"
          rules={[
            {
              required: true,
              message: "Başlık boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<Label.LabelItem>
          label="Yazı"
          name="text"
          rules={[
            {
              required: true,
              message: "Yazı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Ait Olduğu Kampanya"
          name="campaignId"
          rules={[
            {
              required: true,
              message: "Kampanya seçimi zorunludur.",
            },
          ]}
        >
          <Select
            {...campaignSelectProps}
            placeholder="Kampanya seçin"
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Sıra"
          name="order"
          rules={[
            {
              required: true,
              message: "Sıra boş olamaz.",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item
          label="Fotoğraf"
          name="imageUrl"
          rules={[
            {
              required: true,
              message: "Fotoğraf yüklemek zorunludur.",
            },
          ]}
        >
          <Upload.Dragger
            name="files"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          label="Aktif Mi?"
          name="isActive"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Aktiflik durumu seçmek zorunludur.",
            },
          ]}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
