import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  ModalProps,
  FormProps,
  Select,
  Upload,
  message,
  Switch,
  useSelect,
} from "@pankod/refine-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Label } from "interfaces";
import { file2Base64 } from "@pankod/refine-core";

type Props = {
  formProps: FormProps & { initialValues?: any };
  modalProps: ModalProps;
};

interface PrizeFormValues {
  name: string;
  labelId: string;
  weight: number;
  imageUrl: string;
  notificationText: string;
  isActive: boolean;
}

export const PrizeEditModal: React.FC<Props> = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null);

  // useSelect ile label verilerini çekiyoruz
  const { selectProps: labelSelectProps } = useSelect<Label.LabelItem>({
    resource: "wof/label", // API endpoint'i
    optionLabel: "title", // Gösterilecek alan
    optionValue: "id", // Seçilen değerin alanı
  });

  useEffect(() => {
    if (formProps.initialValues) {
      resetForm();
    }
  }, [formProps.initialValues]);

  const resetForm = () => {
    const initialValues = formProps.initialValues || {};
    formProps.form?.setFieldsValue(initialValues);

    if (initialValues.imageUrl) {
      const existingFile = [
        {
          uid: "1",
          name: "Image",
          status: "done",
          url: initialValues.imageUrl,
        },
      ];
      setFileList(existingFile);
      setBase64Image(initialValues.imageUrl);
    } else {
      setFileList([]);
      setBase64Image(null);
    }
  };

  const onFinish = async (values: PrizeFormValues) => {
    const formattedValues = {
      ...values,
      imageUrl: base64Image || "",
    };
    return formProps.onFinish?.(formattedValues);
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title="Ödül Düzenle"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        resetForm();
      }}
    >
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="İsim"
          name="name"
          rules={[
            {
              required: true,
              message: "İsim boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Etiket"
          name="labelId"
          rules={[
            {
              required: true,
              message: "Etiket seçimi zorunludur.",
            },
          ]}
        >
          <Select
            {...labelSelectProps}
            placeholder="Etiket seçin"
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Ağırlık"
          name="weight"
          rules={[
            {
              required: true,
              message: "Ağırlık boş olamaz.",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item
          label="Fotoğraf"
          name="imageUrl"
          rules={[
            {
              required: true,
              message: "Fotoğraf yüklemek zorunludur.",
            },
          ]}
        >
          <Upload.Dragger
            name="files"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          label="Bildirim Metni"
          name="notificationText"
          rules={[
            {
              required: true,
              message: "Bildirim metni boş olamaz.",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Aktif Mi?"
          name="isActive"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Aktiflik durumu seçmek zorunludur.",
            },
          ]}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
