import React, { useState } from "react";
import { file2Base64 } from "@pankod/refine-core";
import {
  Form,
  Input,
  Modal,
  ModalProps,
  FormProps,
  Select,
  Upload,
  message,
} from "@pankod/refine-antd";
import { useSelect } from "@pankod/refine-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Catalog } from "interfaces";

type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

interface SubCatalogFormValues {
  name: string;
  catalog: string;
  order: string;
  banners: string[];
}

export const CreateSubCatalog: React.FC<Props> = ({
  formProps,
  modalProps,
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Banners, setBase64Banners] = useState<string[]>([]);

  const { selectProps: catalogSelectProps } = useSelect<Catalog.CatalogItem>({
    resource: "catalog",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: undefined,
  });

  const onFinish = async (values: SubCatalogFormValues) => {
    const formattedValues = {
      ...values,
      order: parseInt(values.order, 10),
      banners: base64Banners,
    };
    resetState();
    return formProps.onFinish?.(formattedValues);
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 3) {
      message.error("Maksimum 3 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    const base64Results = await Promise.all(
      newFileList.map((file: any) => file2Base64(file))
    );

    setBase64Banners(base64Results);
  };

  const resetState = () => {
    setFileList([]);
    setBase64Banners([]);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title="Yeni Alt Katalog Oluştur"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        resetState(); // Dosya listesini sıfırla
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onReset={resetState} // Form sıfırlandığında state'i temizle
      >
        <Form.Item
          label="İsim"
          name="name"
          rules={[
            {
              required: true,
              message: "İsim boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Katalog"
          name="catalog"
          rules={[
            {
              required: true,
              message: "Katalog seçimi zorunludur.",
            },
          ]}
        >
          <Select
            {...catalogSelectProps}
            placeholder="Katalog seçin"
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Gösterim Sırası"
          name="order"
          rules={[
            {
              required: true,
              message: "Gösterim Sırası boş olamaz.",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item label="Bannerlar" name="banners">
          <Upload.Dragger
            name="files"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false} // Dosyayı sunucuya göndermeden işlemek için
          >
            {fileList.length >= 3 ? null : uploadButton}
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};
