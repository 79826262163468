import {
  Carousel,
  DeleteButton,
  EditButton,
  Icons,
  Image,
  List,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { Table } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import dayjs from "dayjs";
import { Prize } from "interfaces/prizes";
import { CreatePrize } from "./create";
import { PrizeEditModal } from "./edit";

export const PrizeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Prize.PrizeItem>({
    resource: "prize",
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: showCreateModal,
  } = useModalForm({
    resource: "wof/prize",
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Ödül başarıyla oluşturuldu.",
    },
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: showEditModal,
  } = useModalForm({
    resource: "wof/prize",
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Ödül başarıyla güncellendi.",
    },
  });

  return (
    <List
      resource="prize"
      title="Ödüller"
      createButtonProps={{
        type: "primary",
        size: "large",
        shape: "round",
        icon: <Icons.PlusCircleFilled />,
        children: "Yeni Ödül Oluştur",
        onClick: () => showCreateModal(),
      }}
    >
      <Table {...tableProps}>
        <Table.Column<Prize.PrizeItem>
          dataIndex="imageUrl"
          title="Resim"
          key="imageUrl"
          render={(value) =>
            value ? (
              <Image
                src={value}
                alt="Prize Image"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <span>Resim Yok</span>
            )
          }
        />
        <Table.Column
          dataIndex="name"
          title="İsim"
          key="name"
          sorter
          render={(value) => <span>{value}</span>}
        />

        <Table.Column
          dataIndex="weight"
          title="Ağırlık"
          key="weight"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column
          dataIndex="notificationText"
          title="Bildirim Metni"
          key="notificationText"
          render={(value) => <span>{value}</span>}
        />
        <Table.Column
          dataIndex={["label", "title"]}
          title="Ait Olduğu Label"
          key="labelId"
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<Prize.PrizeItem>
          dataIndex="isActive"
          title="Aktif Mi?"
          key="isActive"
          render={(value) => <span>{value ? "Evet" : "Hayır"}</span>}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Oluşturulma Tarihi"
          key="createdAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Güncellenme Tarihi"
          key="updatedAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column<Prize.PrizeItem>
          title="İşlemler"
          dataIndex="actions"
          render={(_, record) => (
            <>
              <EditButton
                resourceNameOrRouteName="wof/prize"
                size="small"
                onClick={() => {
                  showEditModal(record.id);
                }}
              >
                Düzenle
              </EditButton>
              <DeleteButton
                resourceNameOrRouteName="wof/prize"
                size="small"
                recordItemId={record.id}
                successNotification={{
                  type: "success",
                  message: "Ödül başarıyla silindi.",
                }}
              >
                Sil
              </DeleteButton>
            </>
          )}
        />
      </Table>
      <CreatePrize formProps={createFormProps} modalProps={createModalProps} />
      <PrizeEditModal formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};
