import {
  Form,
  Input,
  Select,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  InputNumber,
  useSelect,
} from "@pankod/refine-antd";

import { TagTypes } from "interfaces/enums";
import { useState, useEffect } from "react";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const TagsEdit: React.FC<Props> = ({ formProps, modalProps }) => {
  const [isSubCatalogSelected, setIsSubCatalogSelected] = useState<boolean>(
    !!formProps.initialValues?.subCatalog?.id
  );

  const { selectProps: subCatalogSelectProps } = useSelect({
    resource: "subcatalog",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: formProps.initialValues?.subCatalog?.id,
  });

  // Bu effect, subCatalog seçildiğinde sıralama alanını gösterecek
  useEffect(() => {
    if (formProps.initialValues?.subCatalog?.id) {
      setIsSubCatalogSelected(true);
    } else {
      setIsSubCatalogSelected(false);
    }
  }, [formProps.initialValues?.subCatalog?.id]);

  return (
    <Modal {...modalProps} title="Kullanıcı Ekle" width={400}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          name: formProps.initialValues?.name,
          description: formProps.initialValues?.description,
          type: formProps.initialValues?.type,
          isActive: formProps.initialValues?.isActive ?? true,
          subCatalogOrder: formProps.initialValues?.subCatalogOrder,
          subCatalog: {
            id: formProps.initialValues?.subCatalog?.id,
          },
        }}
      >
        <Form.Item
          label="Etiket Adı"
          name="name"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket adı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Açıklama"
          name="description"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket açıklaması boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Alt Katalog"
          name={["subCatalog", "id"]}
          validateTrigger="onBlur"
        >
          <Select
            {...subCatalogSelectProps}
            allowClear
            placeholder="Alt Katalog Seçin"
            onChange={(value) => setIsSubCatalogSelected(!!value)}
          />
        </Form.Item>

        {/* SubCatalog seçildiğinde sıralama alanını göster */}
        {isSubCatalogSelected && (
          <Form.Item
            label="Alt Katalog Gösterim Sırası"
            name="subCatalogOrder"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Alt katalog gösterim sırası boş olamaz.",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        )}

        <Form.Item
          label="Etiket Tipi"
          name="type"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket tipi boş olamaz.",
            },
          ]}
        >
          <Select
            options={[
              {
                value: TagTypes.ASSET,
                label: "Asset",
              },
              {
                value: TagTypes.TEMPLATE,
                label: "Template",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Durumu"
          name="isActive"
          rules={[
            {
              required: true,
              message: "Etiket durumunu seçiniz.",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Aktif",
                value: true,
              },
              {
                label: "Pasif",
                value: false,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
