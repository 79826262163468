import { Tabs } from "@pankod/refine-antd";
import { CatalogList } from "./catalog/list";
import { SubCatalogList } from "./subcatalog/list";
import { useResource } from "@pankod/refine-core";

export const CatalogManagementList: React.FC = () => {
  const { resource: catalogResource } = useResource({
    resourceNameOrRouteName: "catalog",
  });
  const { resource: subCatalogResource } = useResource({
    resourceNameOrRouteName: "subcatalog",
  });
  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Katalog" key={1}>
          <CatalogList name={catalogResource.name}></CatalogList>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Alt Katalog" key={2}>
          <SubCatalogList name={subCatalogResource.name}></SubCatalogList>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
