import { Form, Input, Modal, ModalProps, FormProps } from "@pankod/refine-antd";
type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

export const CatalogEdit: React.FC<Props> = ({ formProps, modalProps }) => {
  return (
    <Modal {...modalProps} title="Katalogu Düzenle" width={400}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="İsim"
          name="name"
          rules={[
            {
              required: true,
              message: "Katalog adı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* Diğer form alanlarını burada ekleyebilirsiniz */}
      </Form>
    </Modal>
  );
};
