import React, { useState } from "react";
import { file2Base64 } from "@pankod/refine-core";
import {
  Form,
  Input,
  Modal,
  ModalProps,
  FormProps,
  Select,
  Upload,
  message,
  Switch,
} from "@pankod/refine-antd";
import { useSelect } from "@pankod/refine-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Label } from "interfaces";

type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

interface PrizeFormValues {
  name: string;
  label: Label.LabelItem;
  weight: number;
  imageUrl: string;
  notificationText: string;
  isActive: boolean;
}

export const CreatePrize: React.FC<Props> = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null); // Tek bir base64 string

  const { selectProps: catalogSelectProps } = useSelect<Label.LabelItem>({
    resource: "wof/label",
    optionLabel: "title",
    optionValue: "id",
    defaultValue: undefined,
  });

  const onFinish = async (values: PrizeFormValues) => {
    const formattedValues = {
      ...values,
      imageUrl: base64Image || "", // Tek bir fotoğraf URL'si
    };
    resetState();
    return formProps.onFinish?.(formattedValues);
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
    }
  };

  const resetState = () => {
    setFileList([]);
    setBase64Image(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title="Yeni Alt Katalog Oluştur"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        resetState(); // Dosya listesini sıfırla
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onReset={resetState} // Form sıfırlandığında state'i temizle
      >
        <Form.Item
          label="İsim"
          name="name"
          rules={[
            {
              required: true,
              message: "İsim boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Etiket"
          name="labelId"
          rules={[
            {
              required: true,
              message: "Etiket seçimi zorunludur.",
            },
          ]}
        >
          <Select
            {...catalogSelectProps}
            placeholder="Etiket seçin"
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Ağırlık"
          name="weight"
          rules={[
            {
              required: true,
              message: "Ağırlık boş olamaz.",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item label="Fotoğraf" name="imageUrl">
          <Upload.Dragger
            name="files"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false} // Dosyayı sunucuya göndermeden işlemek için
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          label="Bildirim Metni"
          name="notificationText"
          rules={[
            {
              required: true,
              message: "Bildirim metni boş olamaz.",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Aktif Mi?"
          name="isActive"
          initialValue={true}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Aktiflik durumu seçmek zorunludur.",
            },
          ]}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
