import { Form, Input, Modal, ModalProps, FormProps } from "@pankod/refine-antd";

type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

export const CatalogCreate: React.FC<Props> = ({ formProps, modalProps }) => {
  return (
    <Modal {...modalProps} title="Yeni Katalog Oluştur" width={400}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Katalog Adı"
          name="name"
          rules={[
            {
              required: true,
              message: "Katalog adı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* Burada farklı alanlar da eklenebilir (örneğin, açıklama vs.) */}
      </Form>
    </Modal>
  );
};
