import {
  Carousel,
  DeleteButton,
  EditButton,
  Icons,
  Image,
  List,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { Table } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import dayjs from "dayjs";
import { SubCatalog } from "interfaces/subcatalog";
import { CreateSubCatalog } from "./create";
import { EditSubCatalog } from "./edit";

export const SubCatalogList: React.FC<IResourceComponentsProps> = ({
  name,
}) => {
  const { tableProps } = useTable<SubCatalog.SubCatalogItem>({
    resource: name,
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
  });

  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: showCreateModal,
  } = useModalForm({
    resource: name,
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Alt katalog başarıyla oluşturuldu.",
    },
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: showEditModal,
  } = useModalForm({
    resource: name,
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Alt katalog başarıyla güncellendi.",
    },
  });

  return (
    <List
      resource={name}
      title="Alt Kataloglar"
      createButtonProps={{
        type: "primary",
        size: "large",
        shape: "round",
        icon: <Icons.PlusCircleFilled />,
        children: "Yeni Alt Katalog Oluştur",
        onClick: () => showCreateModal(),
      }}
    >
      <Table {...tableProps}>
        <Table.Column
          dataIndex="banners"
          title="Bannerlar"
          key="carousel"
          render={(banners: string[]) =>
            banners && banners.length > 0 ? (
              <div
                style={{
                  width: "200px",
                  height: "120px",
                  margin: "0 auto",
                  overflow: "hidden",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                }}
              >
                {banners.length === 1 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={banners[0]}
                      alt="Banner"
                      style={{
                        maxWidth: "100%",
                        height: "120px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : (
                  <Carousel
                    autoplay={false}
                    style={{
                      width: "100%",
                      height: "120px",
                    }}
                  >
                    {banners.map((url, index) => (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          height: "120px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          src={url}
                          alt={`Banner ${index + 1}`}
                          style={{
                            maxWidth: "100%",
                            height: "120px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            ) : (
              <span>Banner bulunamadı.</span>
            )
          }
        />
        <Table.Column
          dataIndex="name"
          title="İsim"
          key="name"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Oluşturulma Tarihi"
          key="createdAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Güncellenme Tarihi"
          key="updatedAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column
          dataIndex="order"
          title="Gösterim Sırası"
          key="order"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column
          dataIndex={["catalog", "name"]}
          title="Ait Olduğu Katalog"
          key="catalog"
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<SubCatalog.SubCatalogItem>
          title="İşlemler"
          dataIndex="actions"
          render={(_, record) => (
            <>
              <EditButton
                size="small"
                onClick={() => {
                  showEditModal(record.id);
                }}
              >
                Düzenle
              </EditButton>
              <DeleteButton
                resourceNameOrRouteName={name}
                size="small"
                recordItemId={record.id}
                successNotification={{
                  type: "success",
                  message: "Alt katalog başarıyla silindi.",
                }}
              >
                Sil
              </DeleteButton>
            </>
          )}
        />
      </Table>
      <CreateSubCatalog
        formProps={createFormProps}
        modalProps={createModalProps}
      />
      <EditSubCatalog formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};
