import { useTitle, useNavigation } from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icon, Icons } from "@pankod/refine-antd";
import { useMenu } from "@pankod/refine-core";

import { useAuth } from "definitions";
import { RoleTypes } from "interfaces/enums";
import { CSSProperties } from "react";
import { useDashboard } from "dashboardProvider";

export const Sider: React.FC<{
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ collapsed, setCollapsed }) => {
  const Title = useTitle();
  const { menuItems, selectedKey } = useMenu();
  const {
    campaignType,
    setCampaignType,
    setSelectedDashboard,
    setSelectedOption,
  } = useDashboard();
  const { push } = useNavigation();

  const isAuthorized = useAuth();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  return (
    <AntdLayout.Sider
      collapsible
      breakpoint="lg"
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        className="menu"
        theme="dark"
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (
            (key === "/lottery" && campaignType === "trivia") ||
            (key === "/trivia" && campaignType === "lottery")
          ) {
            setCampaignType(null);
            setSelectedDashboard(null);
            setSelectedOption(null);
          }
          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
      >
        {menuItems
          .filter(
            (p) =>
              p.key !== "dashboard" &&
              p.name !== "trivia" &&
              p.name !== "lottery" &&
              p.name !== "app-information" &&
              p.name !== "catalog-management" &&
              p.name !== "wof/prize" &&
              p.name !== "wof/label"
          )
          .map(({ icon, route, label, name, key }) => {
            if (
              (route === "/users" || route === "/tags") &&
              !isAuthorized(RoleTypes.SYSTEM_ADMIN)
            ) {
              return null;
            }

            if (
              route === "/contents/review" &&
              !isAuthorized(
                RoleTypes.SALES_MANAGER,
                RoleTypes.REPORTER,
                RoleTypes.SYSTEM_ADMIN
              )
            ) {
              return null;
            }

            if (
              route === "/contents" &&
              !isAuthorized(RoleTypes.SHOP_MANAGER, RoleTypes.SYSTEM_ADMIN)
            ) {
              return null;
            }

            if (
              (route === "/assets" || route === "/files") &&
              !isAuthorized(RoleTypes.ASSET_MANAGER, RoleTypes.SYSTEM_ADMIN)
            ) {
              return null;
            }

            if (
              route === "/trivia" &&
              !isAuthorized(
                RoleTypes.SUPER_ADMIN,
                RoleTypes.SYSTEM_ADMIN,
                RoleTypes.SALES_MANAGER,
                RoleTypes.REPORTER,
                RoleTypes.SHOP_MANAGER
              )
            ) {
              return null;
            }
            if (
              route === "/lottery" &&
              !isAuthorized(
                RoleTypes.SUPER_ADMIN,
                RoleTypes.SYSTEM_ADMIN,
                RoleTypes.SALES_MANAGER,
                RoleTypes.REPORTER,
                RoleTypes.SHOP_MANAGER
              )
            ) {
              return null;
            }
            if (
              route === "/campaign-management" &&
              !isAuthorized(RoleTypes.SUPER_ADMIN)
            ) {
              return null;
            }
            if (
              route === "/app-information" &&
              !isAuthorized(RoleTypes.SUPER_ADMIN)
            ) {
              return null;
            }
            return (
              <Menu.Item key={route} icon={icon}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {label}
                </div>
              </Menu.Item>
            );
          })}

        <Menu.SubMenu
          popupClassName="menu"
          className="menu"
          title="Kampanyalar"
          key="kampanyalar"
          icon={<Icons.AppstoreOutlined />}
        >
          {menuItems
            .filter(({ route }) => route === "/trivia" || route === "/lottery")
            .map(({ icon, route, label }) => {
              if (
                !isAuthorized(
                  RoleTypes.SUPER_ADMIN,
                  RoleTypes.SYSTEM_ADMIN,
                  RoleTypes.SALES_MANAGER,
                  RoleTypes.REPORTER,
                  RoleTypes.SHOP_MANAGER
                )
              ) {
                return null;
              }
              return (
                <Menu.Item key={route} icon={icon} className="menu">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {label}
                  </div>
                </Menu.Item>
              );
            })}
        </Menu.SubMenu>
        {menuItems
          .filter(
            ({ route }) =>
              route === "/app-information" ||
              route === "/catalog-management" ||
              route === "/label" ||
              route === "/prize"
          )
          .map(({ icon, route, label }) => {
            if (!isAuthorized(RoleTypes.SUPER_ADMIN)) {
              return null;
            }
            return (
              <Menu.Item key={route} icon={icon}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {label}
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </AntdLayout.Sider>
  );
};

const antLayoutSider: CSSProperties = {
  position: "relative",
};
const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 999,
};
