import {
  Form,
  Input,
  Select,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  InputNumber,
  useSelect,
} from "@pankod/refine-antd";

import { TagTypes } from "interfaces/enums";
import { useState } from "react";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const TagsCreate: React.FC<Props> = ({ formProps, modalProps }) => {
  const [isSubCatalogSelected, setIsSubCatalogSelected] =
    useState<boolean>(false);

  const { selectProps: subCatalogSelectProps } = useSelect({
    resource: "subcatalog",
    optionLabel: "name",
    optionValue: "id",
  });

  // Alt katalog seçimi değiştiğinde sıralama alanını gösterip göstermeyeceğimizi belirliyoruz.
  const handleSubCatalogChange = (value: any) => {
    setIsSubCatalogSelected(!!value);
  };

  return (
    <Modal {...modalProps} title="Kullanıcı Ekle" width={400}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          label="Etiket Adı"
          name="name"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket adı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Açıklama"
          name="description"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket açıklaması boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Alt Katalog"
          name={["subCatalog", "id"]}
          validateTrigger="onBlur"
        >
          <Select
            {...subCatalogSelectProps}
            allowClear
            placeholder="Alt Katalog Seçin"
            onChange={handleSubCatalogChange} // Alt katalog seçimi değiştiğinde handleSubCatalogChange çalışacak
          />
        </Form.Item>

        {/* Alt Katalog seçildiyse sıralama alanını göster */}
        {isSubCatalogSelected && (
          <Form.Item
            label="Sıralama"
            name="subCatalogOrder"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Alt katalog sıralamasını giriniz.",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        )}

        <Form.Item
          label="Etiket Tipi"
          name="type"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket tipi boş olamaz.",
            },
          ]}
        >
          <Select
            options={[
              {
                value: TagTypes.ASSET,
                label: "Asset",
              },
              {
                value: TagTypes.TEMPLATE,
                label: "Template",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Durumu"
          name="isActive"
          rules={[
            {
              required: true,
              message: "Etiket durumunu seçiniz.",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Aktif",
                value: true,
              },
              {
                label: "Pasif",
                value: false,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
