import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Typography,
  useModalForm,
  Radio,
  useSelect,
  Select,
} from "@pankod/refine-antd";

import { TagsCreate, TagsEdit } from "./";

import { TagTypes } from "interfaces/enums";
import { Tags } from "interfaces";

const { Title } = Typography;

export const TagsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Tags.TagItem>();

  const { selectProps: subCatalogSelectProps } = useSelect({
    resource: "subcatalog",
    optionLabel: "name",
    optionValue: "id",
  });

  const {
    formProps: tagCreateFormProps,
    modalProps: tagCreateModalProps,
    show: tagCreateModalShow,
  } = useModalForm({
    action: "create",
    submitOnEnter: true,
  });

  const {
    formProps: tagEditFormProps,
    modalProps: tagEditModalProps,
    show: tagEditModalShow,
  } = useModalForm<Tags.TagItem>({
    action: "edit",
    submitOnEnter: true,
  });

  return (
    <>
      <List
        title=""
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.UploadOutlined />,
          onClick: () => tagCreateModalShow(),
        }}
      >
        <Table {...tableProps} scroll={{ x: 1 }} rowKey="id">
          <Table.Column title="Etiket Adı" dataIndex="name" />
          <Table.Column<Tags.TagItem>
            title="Alt Katalog"
            dataIndex={["subCatalog", "id"]}
            render={(_, record) => (
              <span>{record.subCatalog ? record.subCatalog.name : "-"}</span>
            )}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Title level={5}>Alt Katalog</Title>
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  placeholder="Alt Katalog Seçin"
                  {...subCatalogSelectProps}
                />
              </FilterDropdown>
            )}
          />
          <Table.Column<Tags.TagItem>
            title="Alt Katalog Gösterim Sırası"
            dataIndex="subCatalogOrder"
            sorter
            render={(value) => value || "-"}
          />
          <Table.Column<Tags.TagItem>
            title="Tip"
            dataIndex="type"
            render={(_, tag) => (
              <span>
                <Tag>{tag.type}</Tag>
              </span>
            )}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Etiket Tipi</Title>
                  <Radio.Group
                    options={[
                      { label: "Asset", value: TagTypes.ASSET },
                      { label: "Template", value: TagTypes.TEMPLATE },
                    ]}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Tags.TagItem>
            title="Durum"
            dataIndex="isActive"
            render={(_, record) => {
              if (record.isActive) {
                return <Tag color="success">Aktif</Tag>;
              }

              return <Tag color="red">Pasif</Tag>;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Durum</Title>
                  <Radio.Group
                    options={[
                      { label: "Aktif", value: "1" },
                      { label: "Pasif", value: "0" },
                    ]}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Tags.TagItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                onClick={() => {
                  tagEditModalShow(record.id);
                }}
              >
                Düzenle
              </EditButton>
            )}
          />
        </Table>
      </List>
      <TagsCreate
        formProps={tagCreateFormProps}
        modalProps={tagCreateModalProps}
      />
      <TagsEdit formProps={tagEditFormProps} modalProps={tagEditModalProps} />
    </>
  );
};
