import React, { useState, useEffect } from "react";
import { file2Base64, useCustom } from "@pankod/refine-core";
import {
  Form,
  Input,
  Modal,
  ModalProps,
  FormProps,
  Select,
  Upload,
  message,
  Switch,
} from "@pankod/refine-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Label } from "interfaces";
import { Campaigns } from "interfaces/campaigns";
import ENV from "utils/env";

type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

interface WofLabelFormValues {
  title: string;
  text: string;
  campaignId: string;
  isActive: boolean;
  imageUrl: string;
  order: number;
}

export const CreateWofLabel: React.FC<Props> = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null);

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign`,
    method: "get",
    config: {
      query: {
        campaignType: "wof",
      },
    },
    errorNotification: false,
    queryOptions: {
      retry: 0,
    },
  });

  // isError durumunda kullanıcıya mesaj göster
  useEffect(() => {
    if (isError) {
      message.error(
        "Şans Çarkına ait bir kampanya bulunamamıştır. Öncelikle Şans Çarkına ait bir kampanya oluşturun."
      );
    }
  }, [isError]);

  // API'den gelen veriyi useSelect ile uyumlu hale getir
  const campaignSelectProps = {
    options: response?.data?.map((campaign: Campaigns.CampaignItem) => ({
      label: campaign.label,
      value: campaign.id,
    })),
    loading: isFetching,
  };

  const onFinish = async (values: WofLabelFormValues) => {
    const formattedValues = {
      ...values,
      imageUrl: base64Image || "",
    };
    resetState();
    return formProps.onFinish?.(formattedValues);
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
    }
  };

  const resetState = () => {
    setFileList([]);
    setBase64Image(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  // isError durumunda formu render etme
  if (isError) {
    return null; // veya alternatif bir UI gösterilebilir
  }

  return (
    <Modal
      {...modalProps}
      title="Yeni Çark Dilimi Oluştur"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        resetState();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onReset={resetState}
      >
        <Form.Item<Label.LabelItem>
          label="Başlık"
          name="title"
          rules={[
            {
              required: true,
              message: "Başlık boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<Label.LabelItem>
          label="Yazı"
          name="text"
          rules={[
            {
              required: true,
              message: "Yazı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Ait Olduğu Kampanya"
          name="campaignId"
          rules={[
            {
              required: true,
              message: "Kampanya seçimi zorunludur.",
            },
          ]}
        >
          <Select
            {...campaignSelectProps}
            placeholder="Kampanya seçin"
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Sıra"
          name="order"
          rules={[
            {
              required: true,
              message: "Sıra boş olamaz.",
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item
          label="Fotoğraf"
          name="imageUrl"
          rules={[
            {
              required: true,
              message: "Fotoğraf yüklemek zorunludur.",
            },
          ]}
        >
          <Upload.Dragger
            name="files"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          label="Aktif Mi?"
          name="isActive"
          initialValue={true}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Aktiflik durumu seçmek zorunludur.",
            },
          ]}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
