import {
  DeleteButton,
  EditButton,
  Icons,
  Image,
  List,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { Table } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import dayjs from "dayjs";
import { Label } from "interfaces";
import { CreateWofLabel } from "./create";
import { EditWofLabel } from "./edit";

export const WofLabelList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Label.LabelItem>({
    resource: "wof/label",
    initialSorter: [
      {
        field: "text",
        order: "asc",
      },
    ],
  });

  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: showCreateModal,
  } = useModalForm({
    resource: "wof/label",
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Label başarıyla oluşturuldu.",
    },
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: showEditModal,
  } = useModalForm({
    resource: "wof/label",
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Label başarıyla güncellendi.",
    },
  });

  return (
    <List
      resource="label"
      title="Şans Çarkı Dilim Yönetimi"
      createButtonProps={{
        type: "primary",
        size: "large",
        shape: "round",
        icon: <Icons.PlusCircleFilled />,
        children: "Yeni Dilim Oluştur",
        onClick: () => showCreateModal(),
      }}
    >
      <Table {...tableProps}>
        <Table.Column<Label.LabelItem>
          dataIndex="imageUrl"
          title="Resim"
          key="imageUrl"
          render={(value) =>
            value ? (
              <Image
                src={value}
                alt="Label Image"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <span>Resim Yok</span>
            )
          }
        />
        <Table.Column<Label.LabelItem>
          dataIndex="title"
          title="Başlık"
          key="title"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<Label.LabelItem>
          dataIndex="text"
          title="Metin"
          key="text"
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<Label.LabelItem>
          dataIndex={["campaign", "label"]}
          title="Ait Olduğu Kampanya"
          key="campaign"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<Label.LabelItem>
          dataIndex="order"
          title="Sıra"
          key="order"
          sorter
          render={(value) => <span>{value}</span>}
        />
        <Table.Column<Label.LabelItem>
          dataIndex="isActive"
          title="Aktif Mi?"
          key="isActive"
          render={(value) => <span>{value ? "Evet" : "Hayır"}</span>}
        />
        <Table.Column<Label.LabelItem>
          dataIndex="createdAt"
          title="Oluşturulma Tarihi"
          key="createdAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column<Label.LabelItem>
          dataIndex="updatedAt"
          title="Güncellenme Tarihi"
          key="updatedAt"
          sorter
          render={(value) => (
            <span>{dayjs(value).format("DD/MM/YYYY HH:mm")}</span>
          )}
        />
        <Table.Column<Label.LabelItem>
          title="İşlemler"
          dataIndex="actions"
          render={(_, record) => (
            <>
              <EditButton
                resourceNameOrRouteName="wof/label"
                size="small"
                onClick={() => {
                  showEditModal(record.id);
                }}
              >
                Düzenle
              </EditButton>
              <DeleteButton
                resourceNameOrRouteName="wof/label"
                size="small"
                recordItemId={record.id}
                successNotification={{
                  type: "success",
                  message: "Label başarıyla silindi.",
                }}
              >
                Sil
              </DeleteButton>
            </>
          )}
        />
      </Table>
      <CreateWofLabel
        formProps={createFormProps}
        modalProps={createModalProps}
      />
      <EditWofLabel formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};
